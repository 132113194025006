import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Index from './pages/Index';
import Interno from './pages/Interno';

export default function Routes(){
    return (
        <BrowserRouter>
            <Route path='/' exact component={Index} />
            <Route path='/:geocode/:tipo' exact component={Index} />
            <Route path='/interno' exact component={Interno} />
        </BrowserRouter>
    );
}